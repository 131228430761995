import React from 'react';
import NavBarMain from '../components/navbars/NavBarMain';
import Header from '../components/headers/HeaderBlogPosts';
import Footer from '../components/main/Footer';
import { Col, Container, Row } from 'reactstrap';
import Blog from '../components/blogs/Blogs2';
import Feature7 from '../components/features/Feature7';
import SpeceSheet from '../components/main/SpeceSheet';
import { FaFileAlt } from 'react-icons/fa';
import Gallery from '../components/LOB/Gallery';

function Space() {

  const specsData = [
    {
      icon: <FaFileAlt color='white' size={1} />,
      iconColor: 'success',
      title: 'Main Room',
      list: [
        "Capacity - 1200",
        '1x - 14.6” Wide x 40” tall video wall - 3.9 pitch 1152 x 256 pixels',
        "4x - Corner EAW double 15” mains flown / new QSC amps",
        "2x - Danley th118 XL subs /danley/powersoft amp",
        "2x - EAW single 18” front loaded subs.",
        "2x - Qsc kw122 monitor",
        "1x - Resolume computer",
        "1x - EAW monitor",
        "8x - Chauvet Maverick Pyxis",
        "8x - Chauvet Rouge FXB",
        "4x - Chauvet Blinder/strobe",
        "12x - Chauvet stage pars",
        "1x - Chamsys mq50 console",
        "6x - Chauvet Rouge R1 moving heads",
        "1x - Chamsys mq50 console",
        "1x - Fog / Haze",
      ]
    },
    {
      icon: <FaFileAlt color='white' size={1} />,
      iconColor: 'success',
      title: 'Front Room',
      list: [
        "Capacity - 400",
        "4x - Dual 15” JBL mains",
        "2x - Dual EAW Avalon subs",
        "8x - Chauvet Rouge R1 Spot",
        "1x - Fog / Haze",
        "1x - Pioneer SRT1000 controller",
      ]
    },
  ]

  return (
    <>
      {/* <NavBarMain type={'trans'} /> */}
      <Header image={'https://715harrison.s3.us-west-1.amazonaws.com/images/banners/spaceBanner.jpeg'} details={'715 HARRISON'} />
      

      <Container className='margin-bottom-70'>
        <Row>
          <Col className="mx-auto margin-bottom-50" md="12">
            <h3 className="display-3 margin-top-30">{'Floor Plan'}</h3>
            <p className="lead">
              Our venue's 15,000 square foot floor plan is a versatile and flexible space with multiple rooms, two dance floors, a large stage, and three full bars. Whether you're hosting a corporate event, private party, or musical performance, our state-of-the-art sound and lighting systems and mobile bars provide everything you need for a successful event.
            </p>
          </Col >

          <Col className="d-flex justify-content-center align-items-center">
            <img src='https://mirrorcorp.s3.us-west-2.amazonaws.com/images/rental/6.png' className='img-fluid' alt='...' />
          </Col>
        </Row>
      </Container>

      <Feature7
        title='Venue Spec Sheet'
      />
      <SpeceSheet
        title="Spece Sheet"
        data={specsData}
        image={'https://715harrison.s3.us-west-1.amazonaws.com/images/adPhotos/22.jpeg'}
      />
      {/* <Feature7
        title='Complete Packages'
        details='We can provide everything you need for a successful event, including fitness equipment rentals, mobile bars, and catering services.'
      /> */}
      {/* <Gallery /> */}


      {/* <Footer /> */}
    </>
  )
}

export default Space